html {
  font-size: inherit !important;
}
*, body {
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  body {
    background-color: #17191d;
  }
  .cover-bg-img {
    filter: blur(20px);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(256, 265, 256, 0.23);
  }
}

.form-card__body {
  height: 100%;
}

.form-card .form-card__img {
  width: 100%;
  min-height: 300px;
  padding: 0;
  background-size: cover;
  background-position: 50% 50%;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
  text-align: center !important;
  margin: auto;
}

.bg-facebook {
  background-color: #335397;
  text-transform: uppercase;
}

.bg-facebook:hover {
  color: #efefef;
}

.bg-google {
  background-color: #4285f4;
  text-transform: uppercase;
}

.bg-google:hover {
  color: #efefef;
}

.icon-btn-v2, a.icon-btn-v2 {
  color: #fff;
  height: auto;
  padding: .5em 2.5em;
}

.form-v1-container .additional-info {
  font-size: 1.2rem;
  margin-bottom: 0;
  line-height: 2.2rem;
}

.invalid-feedback {
  color: #dc3545;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
}

@media screen and (max-width: 766px) {
  a {
    color: #d2c197;
  }
  .form-v1-container .additional-info {
    font-size: 1.4rem;
    margin-bottom: 10px;
    line-height: 2.2rem;
  }
}

.btn {
  padding: 12px !important;
}

.btn-primary {
  background: #d2c197;
  border: #d2c197;
  color: #262626;
  text-shadow: none;

  &:hover,
  &.hover {
    color: #f8f9fa;
    background-color: darken(#d2c197, 3);
    border-color: darken(#d2c197, 3);
  }
  &:active {
    color: #f8f9fa;
  }
  &:focus,
  &.focus {
    background-color: darken(#d2c197, 6);
    border-color: darken(#d2c197, 6);
    box-shadow: 0 0 0 .2rem rgba(darken(#d2c197, 3), .5);
  }
}

.btn-primary-outline {
  background: transparent;
  border: 2px solid #d2c197;
  color: #d2c197;
  text-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: darken(#d2c197, 3);
  border-color: darken(#d2c197, 3);
  box-shadow: 0 0 0 .2rem rgba(darken(#d2c197, 3), .5);
}

.btn-outline-primary {
  background-color: transparent;
  border-color: #d2c197;
  color: #d2c197;

  &:hover,
  &.hover {
    color: #f8f9fa;
    background-color: darken(#d2c197, 3);
    border-color: darken(#d2c197, 3);
  }
  &:active {
    color: #f8f9fa;
  }
  &:focus,
  &.focus {
    background-color: darken(#d2c197, 6);
    border-color: darken(#d2c197, 6);
    box-shadow: 0 0 0 .2rem rgba(darken(#d2c197, 3), .5);
  }
}

.btn-primary.disabled {
  color: #fff;
  background-color: lighten(#d2c197, 3);
  border-color: lighten(#d2c197, 3);
  box-shadow: 0 0 0 .2rem rgba(lighten(#d2c197, 3), .5);

  &:hover,
  &.hover {
    color: #f8f9fa;
    background-color: darken(#d2c197, 3);
    border-color: darken(#d2c197, 3);
  }
}

.btn-text {
  text-transform: none;
}

.confirm {
  background-color: #d2c197 !important;
  color: #262626 !important;
}

.content_body_text p {
  margin-bottom: 14px;
}

.react-actionsheet-action-item {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #d2c197 !important;
}

.btn.btn-success {
  background: #5cb85c;
  border-color: #5cb85c;
  box-shadow: none;
  text-shadow: none;
}

.cover-form-container {
  height: 100vh;
  max-height: 100vh;
  padding: 3rem 0;
  overflow-y: scroll;
}

.card {
//  //background-color: #24262a; // TODO dark mode bg color
//  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px 1px !important;
  background-color: #fff !important;
}

.card-bg-img, .cover-bg-img {
  background-size: cover;
  background-position: 50%;
}

.cover-bg-img {
  background-color: rgb(0,0,0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 350px) {
  .hide-350 {
    display: none;
  }
}

hr.short.blue {
  border-color: #00507f;
}
hr.short.brown {
  border-color: #baac92;
}
hr.short {
  width: 100px;
  margin-top: 30px;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-top: 3px solid #d0bb89;
  border-top-color: #d0bb89;
  position: relative;
  z-index: 1;
}

.divider-with-content {
  display: table;
  margin-top: 20px;
  margin-bottom: 20px;

  &:before,
  &:after {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid #cccccc;
    transform: translateY(50%);
  }

  .divider-inner-content {
    display: inline-block;
    padding: 0 .75rem;
    white-space: nowrap;
    text-align: center;
  }
}

.divider-with-content-inverse {
  display: table;
  margin-top: 20px;
  margin-bottom: 20px;

  &:before,
  &:after {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    transform: translateY(50%);
  }

  .divider-inner-content {
    display: inline-block;
    padding: 0 .75rem;
    white-space: nowrap;
    text-align: center;
  }
}

@media screen and (max-width: 766px) {
  .MuiInputBase-root {
    width: 100% !important;
    text-align: left !important;
  }

  .MuiFormControl-root {
    background-color: rgba(0,0,0,0.4) !important;
  }

  .form-control {
    height: 44px !important;
    background-color: transparent !important;
  }

  legend {
    float: unset !important;
  }
  .MuiButton-textSecondary {
    color: inherit !important;
  }
}

.MuiAvatar-colorDefault {
  color: #fafafa !important;
  background-color: #bdbdbd !important;
}

.choose-account .MuiTypography-colorTextSecondary {
  color: #959595
}

@media screen and (min-width: 769px) {
  .create-account {
    max-width: 550px;
  }
}

@media screen and (max-width: 1024px) {
  section.align-items-center {
    max-width: 550px;
    margin: auto;
  }
}

@media screen and (min-width: 1024px) {
  .cover-form-container > div {
    margin-left: calc(50vw - 350px);
  }
}

.create-account {
  margin-top: 50px;
  width: calc(100vw - 30px);
  text-align: center;
  max-width: 550px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  background-color: transparent !important;
  background-clip: content-box !important;
  transition: background-color 5000s ease-in-out 0s, transparent 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, transparent 5000s;
}

@media screen and (max-width: 676px) {
  #chat-button {
    display: none !important;
  }
}
