$react-ios-modal-options: (
  width: 75%,
  min-width: 300px,
  max-width: 400px
) !default;

$react-modal-options: (
  z-index: (
    modal: 100,
    backdrop: 98
  )
) !default;

.react-modal {
  $z-index: map-get(
    $map: $react-modal-options,
    $key: z-index
  );

  $modal-z-index: map-get(
    $map: $z-index,
    $key: modal
  );

  $backdrop-z-index: map-get(
    $map: $z-index,
    $key: backdrop
  );

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &,
  & + .webkit-sassui-backdrop {
    position: absolute;
    background: rgba(0,0,0,.3);
    top: 0; left: 0; right: 0; bottom: 0
  }

  z-index: $modal-z-index;
  & + .webkit-sassui-backdrop {
    position: fixed;
    z-index: $backdrop-z-index;
  }
}

/*webkit-sassui-ios-modal Start*/
$webkit-sassui-ios-modal-options:(
  width: 100%,
)!default;

.webkit-sassui-ios-modal{
  $width: map-get($map: $webkit-sassui-ios-modal-options, $key: width);

  font-family: -apple-system,"Helvetica Neue",Roboto,sans-serif;
  border-radius: 13px;
  overflow: hidden;
  width: $width;
  //background-color: #f8f8f8;
  text-align: center;

  &,
  & > *{
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  >footer {
    background-color: transparent;
    padding: 0;
  }

  >.hd{
    padding: 14px 16px 0;
    font-size: 17px;
    font-weight: 600;
    & +.ft{
      margin-top: 14px;
    }
  }

  >.bd{
    font-size: 13px;
    padding: 12px 16px;
  }

  >.ft{
    button{
      border: none;
      height: 44px;
      border-top: .55px solid #dbdbdf;
      font-size: 17px;
      color: #488aff;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.3s;
      &:active{
        opacity: .4
      }
    }
  }
}
/*webkit-sassui-ios-modal End*/

/*webkit-sassui-ios-alert Start*/
$webkit-sassui-ios-alert-options:(
)!default;

.webkit-sassui-ios-alert{
  >.ft{
    button{
      width:100%;
    }
  }
}
/*webkit-sassui-ios-alert End*/

/*webkit-sassui-ios-confirm Start*/
$webkit-sassui-ios-confirm-options:(
)!default;

.webkit-sassui-ios-confirm{
  >.ft{
    display: flex;
    button{
      flex:1;
      & + button{
        border-left: 0.55px solid #dbdbdf;
      }
    }
  }
}
/*webkit-sassui-ios-confirm End*/

.react-ios-modal {
  $width: map-get(
    $map: $react-ios-modal-options,
    $key: width
  );

  $min-width: map-get(
    $map: $react-ios-modal-options,
    $key: min-width
  );

  $max-width: map-get(
    $map: $react-ios-modal-options,
    $key: max-width
  );

  background:rgba(255,255,255,0.95);
  border-radius: 8px;
  margin: 50% 10%;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  width: $width;
  min-width: $min-width;
  max-width: $max-width;
}
